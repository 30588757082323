@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .bannerIcon {
        @apply w-14 h-14 bg-black bg-opacity-25 text-gray-200 text-lg inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300;
    }

    .resumeLi {
        @apply w-full h-20 bg-black bg-opacity-25 text-xl text-gray-300 flex justify-center cursor-pointer hover:bg-opacity-40 duration-300 items-center border-[1px] rounded-none;
    }

    .bgOpacity {
        @apply bg-black bg-opacity-40;
    }

    .contactInput {
        @apply w-full h-12 rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300
    }

    .contactTextArea {
        @apply w-full rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 py-2 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300 resize-none
    }
}

.active {
    color: white;
}

body {
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-thumb {
    background-color: #ff014f;
    border-radius: 10px;
}

body::-webkit-scrollbar-button {
    display: none;
}

body::-webkit-scrollbar-track {
    background-color: #2b2e34;
    border-radius: 10px;
}

@keyframes pop-up {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

@keyframes pop-out {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.logo {
    @apply animate-pop-up;
    @apply animate-pop-out;
}

.animate-pop-up {
    animation: pop-up 3s ease infinite;
}

.animate-pop-out {
    animation: pop-out 3s ease infinite;
}